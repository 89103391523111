/* You can add global styles to this file, and also import other style files */
@use 'projects/admin/src/assets/scss/variables' as v;
@use 'projects/admin/src/assets/scss/mixin' as m;
@use '~@angular/material' as mat;

/* @import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; */

html,
body {
  height: 100%;
}

body {
  background: #ffffff;
  margin: 0;
  padding: 0;

  &.mat-typography,
  .p-component {
    font-family: 'Rubik', 'Work Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: inherit;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
  }
}

.chat-icon {
  $icon-size: 40px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 24px;
  height: $icon-size;
  line-height: $icon-size;
  width: $icon-size;
}

.chat-small-icon {
  $icon-size: 32px;
  width: $icon-size;
  height: $icon-size;
  line-height: $icon-size;
  color: #ffffff;
  border-radius: 50%;
  font-size: 18px;
}
// Scroll Bar
::-webkit-scrollbar {
  background-color: #ffffff;
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: #afdef7;
  border: 3px solid #ffffff;
  border-radius: 16px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.core-container {
  background-color: v.$primary-blue-color;
  color: #ffffff;
  min-height: 100vh;
  padding: min(5vw, 40px) 1vw;
  position: relative;

  h1.core-main-title {
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    margin: 30px 0;
    text-align: center;
  }

  h2.core-sub-title {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
  }
}

.underline {
  border-bottom: 4px solid v.$primary-orange-color;
  padding: 0 24px 7px;
}

.go-back {
  align-items: center;
  color: v.$primary-blue-color;
  cursor: pointer;
  display: inline-flex;
  margin-left: -8px;
}

.cancel {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.75);
  transition: 0.25s;

  &:hover {
    background: v.$primary-blue-color;
    color: #ffffff;
  }
}

.title-section {
  align-items: center;
  background: v.$light-blue-color;
  display: flex;
  gap: 10px;
  min-height: 150px;
  padding: 15px;
  width: 100%;

  .details-row {
    align-items: center;
    display: flex;
    gap: 10px 50px;
    width: 100%;

    @media #{v.$lt-md} {
      align-items: start;
      flex-direction: column;
      justify-content: center;
    }
  }

  .right-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: min(100%, 925px);
  }

  .details-container {
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    row-gap: 20px;

    @media #{v.$gt-md} {
      column-gap: 45px;
    }

    @media #{v.$lt-lg} {
      column-gap: 2vw;
    }

    @media #{v.$xs} {
      flex-direction: column;
    }

    .details {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;

      h6 {
        font-size: 14px;
        font-weight: 500;
      }

      p {
        font-size: 12px;
      }
    }

    .address {
      width: 215px;

      span {
        display: block;
      }
    }

    .action-buttons {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      justify-content: start;
    }

    .toggle-buttons {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &.individual {
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .details-row {
      align-items: stretch;
    }
  }
}

.title-count {
  flex-basis: 40%;

  h3 {
    font-size: 24px;
    font-weight: 500;
    overflow-wrap: anywhere;

    span {
      color: v.$primary-orange-color;
    }
  }

  p {
    color: #6c757d;
    margin-bottom: 0.5rem;
    overflow-wrap: anywhere;

    > mat-icon {
      font-size: 16px;
      margin: 0px;
    }

    > span {
      color: v.$primary-blue-color;
    }
  }

  .institute-type {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .sub-title {
    color: v.$primary-orange-color;
    font-size: 12px;
    font-weight: 500;
  }
}

.content-section {
  background-color: #ffffff;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .content-count {
    color: v.$dark-grey-color;
    margin: 0;

    span {
      color: v.$primary-blue-color;
    }
  }
}

.content-title-row {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;

  @media #{v.$lt-md} {
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }
}

.content-filter-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media #{v.$lt-md} {
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
}

.overflow-ellipsis {
  &-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-2 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  &-3 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}

.mat-form-field.search {
  width: 250px;

  .mat-form-field-suffix {
    align-self: flex-end;
  }

  &.mat-focused .mat-icon {
    color: v.$primary-blue-color;
  }
}

.content {
  overflow-x: auto;
  // overflow: auto; // For sticky header
  // height: 500px; // For sticky header
}

.list-buttons-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}

.filter-grid {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  gap: 10px 20px;
}

.filters-container {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;

  @media #{v.$gt-sm} {
    > .p-field {
      width: min(250px, 20vw);
    }
  }

  @media #{v.$lt-md} {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px 20px;
  }
}

.grid-row {
  column-gap: 7%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.notification-form {
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  .notification-grid-row {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
}

.dialog-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 5px;

  .grid-row {
    margin-bottom: 15px;

    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
}

.dialog-row {
  display: flex;
  justify-content: space-between;

  > * {
    width: 100%;
  }

  @media #{v.$lt-md} {
    flex-direction: column;
    gap: 5px;
  }

  @media #{v.$gt-sm} {
    .col- {
      &1 {
        width: 100%;
      }

      &2 {
        width: 47%;
      }

      &3 {
        width: 30%;
      }
    }
  }
}

.dialog-sub-title {
  color: v.$primary-orange-color;
  font-size: 16px;
  margin-bottom: 1rem;
}

.dialog-actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px 15px;
  justify-content: center;
  margin-top: 10px;
}

$item-height: calc(1em + 16px);
mat-dialog-content.mat-dialog-content.scrolling-grid {
  height: calc(100% - 60px);
  max-height: none !important;

  form {
    height: 100%;
  }

  .container {
    $grid-height: calc(100% - 51px);
    display: grid;
    gap: 5px 15px;
    grid-template-rows: repeat(auto-fit, $item-height);
    grid-auto-flow: column;
    grid-auto-columns: 175px;
    overflow-x: auto;
  }

  .individual-item {
    max-height: $item-height;
    min-height: $item-height;

    &.draggable {
      background-color: v.$primary-orange-color;
      border: dotted 3px transparent;
      border-radius: 2px;
      color: #ffffff;
      cursor: move;
      line-height: 1;
      overflow: hidden;
      padding: 5px;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
      word-wrap: break-word;

      &:hover {
        background-color: v.$primary-blue-color;
      }
    }

    p-checkbox {
      max-width: 100%;

      .p-checkbox-label {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: break-word;
      }
    }
  }
}

.cdk-drag-placeholder {
  background-color: rgba(v.$primary-blue-color, 0.5);
  border: dotted 3px v.$primary-orange-color;
  min-height: $item-height;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview {
  background-color: v.$primary-orange-color;
  border: dotted 3px transparent;
  border-radius: 2px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: #ffffff;
  cursor: move;
  line-height: 1;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.container.cdk-drop-list-dragging .individual-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.mqops-wrapper {
  .title-section .details-row {
    margin: auto 0;
  }

  .content-section {
    display: flex;
    align-items: center;
    padding: min(3vw, 45px) 0;
  }

  .mat-card {
    @include mat.elevation(3);
    width: 85%;

    @media #{v.$gt-md} {
      width: 1100px;
    }

    @media #{v.$xs} {
      width: 95%;
    }
  }

  .mat-card-content {
    padding: 0.5rem min(5vw, 35px) 25px;
  }

  label.static {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);

    &.required::after {
      color: v.$error-color;
      content: ' *';
    }
  }

  .p-field.username {
    margin-bottom: 15px;
  }

  .file-upload-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  ::ng-deep .p-messages {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
}

// Error, Page not Found, Access Denied Pages
.error-page-container {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: v.$primary-blue-color;
  flex-direction: column;
  min-height: 100vh;
  padding: min(5vw, 40px) 1vw;

  .mat-card {
    padding: min(5vw, 40px);
    width: min(95vw, 1050px);
  }

  .mat-card-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  }

  img {
    align-self: center;
    justify-self: center;
    width: 80%;
    height: 250px;
    object-fit: contain;
  }

  .content-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;

    @media #{v.$xs} {
      align-items: center;

      > div {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .title {
    font-size: 2rem;
  }

  .sub-title {
    color: v.$light-grey-color;
    font-weight: 400;

    @media #{v.$lt-md} {
      text-align: justify;
    }
  }
}

/* Snack Bar */
.snack-bar-container {
  border-left: 15px solid;

  &.info {
    border-color: v.$primary-blue-color;
  }

  &.success {
    border-color: #689f38;
  }

  &.error {
    border-color: v.$error-color;
  }
}

.cell-disabled {
  cursor: not-allowed !important;
  border: none;
  outline: none;
  background: #eeeeee;
  opacity: 0.5;
}

/* Material */
// Button
button.mat-button,
button.mat-icon-button,
button.mat-stroked-button,
button.mat-flat-button,
button.mat-raised-button {
  border-radius: 30px;
  cursor: pointer;
  font-weight: normal;

  &:disabled {
    cursor: not-allowed;
  }
}

button.mat-button,
button.mat-stroked-button,
button.mat-flat-button,
button.mat-raised-button {
  .mat-button-wrapper {
    align-items: center;
    display: flex;
  }

  .mat-icon {
    $icon-size: 14px;
    font-size: $icon-size;
    height: $icon-size;
    margin-right: 3px;
    width: $icon-size;
  }
}

// Dialog
mat-dialog-container.mat-dialog-container {
  overflow: unset;
  padding: 0;
  position: relative;

  .close-icon {
    height: 30px;
    line-height: 20px;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;

    .mat-icon {
      font-size: 20px;
      height: 20px;
      line-height: 20px;
      width: 20px;
    }
  }

  $padding: 25px;
  $padding-side: min(5vw, 35px);

  .mat-dialog-title {
    font-size: 20px;
    color: v.$primary-orange-color;
    margin-bottom: 0.75rem;
    padding: $padding $padding-side 0;
  }

  .mat-dialog-content {
    margin: 0;
    max-height: 75vh;
    padding: 0.5rem $padding-side $padding;
  }
}

// Form Field
.mat-form-field {
  .mat-form-field-suffix {
    color: v.$light-grey-color;
  }

  .mat-form-field-label-wrapper {
    line-height: 1.5;
  }
}

// Nav Tab
nav.mat-tab-nav-bar {
  a.mat-tab-link {
    font-weight: 400;
    min-width: min-content;

    &.mat-tab-label-active {
      color: v.$primary-orange-color;
      font-weight: 500;
    }
  }
}

// Paginator
mat-paginator.mat-paginator {
  .mat-paginator-range-label {
    margin: 0 1vw 0 1vw;
  }
}

// Table
table.mat-table {
  @include mat.elevation(0);
  min-width: 100%;
  // position: relative; // For sticky header

  tr.mat-header-row {
    height: auto;

    .mat-sort-header-container:not(.mat-sort-header-sorted) {
      .mat-sort-header-arrow {
        display: none;
      }
    }
  }

  th {
    &.mat-header-cell {
      border: none;
      box-shadow: inset 0 -2px 0 #dfe1e6;
      color: rgba(v.$primary-blue-color, 0.75);
      font-size: 15px;
      font-weight: 500;
      padding: 10px;
      padding-top: 0px;
      word-wrap: normal;
      // position: sticky; // For sticky header
      // top: 0px; // For sticky header
      // z-index: 1; // For sticky header
      // background: white; // For sticky header
    }
  }

  td {
    &.mat-cell {
      color: v.$dark-grey-color;
      padding: 5px 10px;
      white-space: pre-wrap;
      word-wrap: break-word;

      a {
        color: v.$primary-blue-color;
        text-decoration: none;
      }

      &.no-data {
        text-align: center;
      }

      .icon-row {
        align-items: center;
        display: flex;
        gap: 2px;
        justify-content: start;
        margin-left: -7px;

        > div {
          $icon-size: 20px;
          align-items: center;
          display: flex;
          flex-basis: calc(#{$icon-size} + 10px);
          justify-content: center;

          .mat-icon {
            font-size: $icon-size;
            height: $icon-size;
            width: $icon-size;
          }
        }

        > span {
          flex: 1 1 0%;
          word-break: break-all;
        }
      }
    }
  }

  .mat-column-status {
    &.active {
      color: v.$primary-blue-color;
    }

    &.inactive {
      color: v.$error-color;
    }
  }

  .mat-column-actions {
    $button-size: 30px;
    $icon-size: 20px;

    .mat-icon-button {
      height: $button-size;
      line-height: $button-size;
      width: $button-size;

      .mat-icon,
      .mat-icon > span {
        font-size: $icon-size;
        height: $icon-size;
        line-height: $icon-size;
        width: $icon-size;
      }
    }
  }
}

/* Prime NG */
@mixin box-shadow($color) {
  box-shadow: inset 0 0 0 1px $color, inset 0 0 0 1px $color, inset 0 0 0 1px $color,
    inset 0 0 0 1px $color;
}

$form-el-height: 36px;
$form-el-font-size: 14px;

.p-disabled,
.p-disabled * {
  cursor: not-allowed !important;
  pointer-events: auto;
}

// Accordian
.p-accordion-header-text {
  color: rgba(v.$primary-blue-color, 0.75);
}

.p-accordion-tab-active {
  .p-accordion-header-text {
    color: v.$primary-blue-color;
  }
}

.p-accordion .p-accordion-header .p-accordion-toggle-icon {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  height: 14px;
  width: 14px;
}

// Autocomplete
p-autocomplete {
  &.ng-dirty.ng-invalid > .p-autocomplete {
    > .p-inputtext {
      border-color: v.$error-color;

      &:enabled:focus {
        @include box-shadow(v.$error-color);
      }
    }

    .p-autocomplete-multiple-container {
      &:not(.p-disabled).p-focus {
        @include box-shadow(v.$error-color);
      }
    }
  }

  &.not-empty {
    .p-autocomplete {
      height: auto;

      .p-inputtext {
        height: auto;
      }
    }
  }
}

.p-autocomplete {
  height: $form-el-height;
  width: 100%;

  .p-inputtext {
    height: $form-el-height;
  }

  .p-autocomplete-multiple-container {
    gap: 5px 8px;

    .p-autocomplete-token {
      background: rgba(v.$primary-blue-color, 0.12);
      color: v.$primary-blue-color;
      margin: 0;
    }

    &:not(.p-disabled).p-focus {
      border-color: v.$primary-blue-color;
      @include box-shadow(v.$primary-blue-color);
    }
  }
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  background: rgba(v.$primary-blue-color, 0.12);
  color: v.$primary-blue-color;
}

// Button
.p-button {
  background: v.$primary-blue-color;

  &:enabled {
    &:hover {
      background: rgba(v.$primary-blue-color, 0.92);
    }

    &:focus {
      background: rgba(v.$primary-blue-color, 0.76);
    }

    &:active {
      background: rgba(v.$primary-blue-color, 0.68);
    }
  }

  &.p-button-outlined {
    color: v.$primary-blue-color;

    &:enabled {
      &:hover {
        background: rgba(v.$primary-blue-color, 0.04);
        color: v.$primary-blue-color;
      }

      &:focus {
        background: rgba(v.$primary-blue-color, 0.12);
      }

      &:active {
        background: rgba(v.$primary-blue-color, 0.16);
        color: v.$primary-blue-color;
      }
    }

    .p-ink {
      background-color: rgba(v.$primary-blue-color, 0.16);
    }
  }

  &.p-button-text {
    color: v.$primary-blue-color;

    &:enabled {
      &:hover {
        background: rgba(v.$primary-blue-color, 0.04);
        color: v.$primary-blue-color;
      }

      &:focus {
        background: rgba(v.$primary-blue-color, 0.12);
      }

      &:active {
        background: rgba(v.$primary-blue-color, 0.16);
        color: v.$primary-blue-color;
      }
    }

    .p-ink {
      background-color: rgba(v.$primary-blue-color, 0.16);
    }
  }

  .p-badge {
    color: v.$primary-blue-color;
  }

  &.p-button-secondary {
    background: v.$primary-orange-color;

    &:enabled {
      &:hover {
        background: rgba(v.$primary-orange-color, 0.92);
      }

      &:focus {
        background: rgba(v.$primary-orange-color, 0.76);
      }

      &:active {
        background: rgba(v.$primary-orange-color, 0.68);
      }
    }

    &.p-button-outlined {
      color: v.$primary-orange-color;

      &:enabled {
        &:hover {
          background: rgba(v.$primary-orange-color, 0.04);
        }

        &:active {
          background: rgba(v.$primary-orange-color, 0.16);
        }
      }
    }

    &.p-button-text {
      color: v.$primary-orange-color;

      &:enabled {
        &:hover {
          background: rgba(v.$primary-orange-color, 0.04);
          color: v.$primary-orange-color;
        }

        &:active {
          background: rgba(v.$primary-orange-color, 0.16);
          color: v.$primary-orange-color;
        }
      }
    }
  }

  &.p-button-link {
    color: v.$primary-blue-color;

    &:enabled {
      &:hover {
        color: v.$primary-blue-color;
      }

      &:active {
        color: v.$primary-blue-color;
      }
    }
  }
}

.p-buttonset.p-button-secondary > .p-button {
  background: v.$primary-orange-color;
  &:enabled {
    &:hover {
      background: rgba(v.$primary-orange-color, 0.92);
    }

    &:focus {
      background: rgba(v.$primary-orange-color, 0.76);
    }

    &:active {
      background: rgba(v.$primary-orange-color, 0.68);
    }
  }

  &.p-button-outlined {
    color: v.$primary-orange-color;

    &:enabled {
      &:hover {
        background: rgba(v.$primary-orange-color, 0.04);
      }

      &:focus {
        background: rgba(v.$primary-orange-color, 0.12);
      }

      &:active {
        background: rgba(v.$primary-orange-color, 0.16);
      }
    }
  }

  &.p-button-text {
    color: v.$primary-orange-color;

    &:enabled {
      &:hover {
        background: rgba(v.$primary-orange-color, 0.04);
        color: v.$primary-orange-color;
      }

      &:active {
        background: rgba(v.$primary-orange-color, 0.16);
        color: v.$primary-orange-color;
      }
    }
  }
}

// Checkbox
p-checkbox {
  &.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    border-color: v.$error-color;
  }
}

.p-checkbox {
  .p-checkbox-box {
    &.p-highlight {
      border-color: v.$primary-blue-color;
      background: v.$primary-blue-color;

      &:not(.p-disabled).p-focus {
        border-color: v.$primary-blue-color;
      }
    }
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box {
      &.p-focus {
        border-color: #757575;
      }

      &.p-highlight:hover {
        border-color: v.$primary-blue-color;
        background: v.$primary-blue-color;
      }
    }

    &.p-checkbox-focused {
      box-shadow: none; // Needed
    }
  }

  &.p-checkbox-checked:not(.p-checkbox-disabled) {
    &:hover {
      box-shadow: 0 0 1px 10px rgba(v.$primary-blue-color, 0.04);
    }

    &.p-checkbox-focused {
      box-shadow: none; // Needed
    }
  }
}

.p-checkbox-label {
  cursor: pointer;
}

.p-input-filled {
  .p-checkbox {
    &.p-highlight {
      background: v.$primary-blue-color;
    }

    &:not(.p-checkbox-disabled) .p-checkbox-box {
      &.p-highlight:hover {
        background: v.$primary-blue-color;
      }
    }
  }
}

// Chips
p-chips {
  &.ng-dirty.ng-invalid > .p-chips {
    > .p-inputtext {
      border-color: v.$error-color;
    }

    .p-chips-multiple-container:not(.p-disabled).p-focus {
      border-color: v.$primary-blue-color;
      @include box-shadow(v.$error-color);
    }
  }
}

.p-chips {
  height: $form-el-height;
  width: 100%;

  .p-chips-multiple-container {
    .p-chips-token {
      background: rgba(v.$primary-blue-color, 0.12);
      color: v.$primary-blue-color;
    }

    &:not(.p-disabled).p-focus {
      @include box-shadow(v.$primary-blue-color);
    }
  }
}

// Dropdown
p-dropdown {
  .p-dropdown {
    height: $form-el-height;
    line-height: $form-el-font-size;
    width: 100%;

    &:not(.p-disabled).p-focus {
      border-color: v.$primary-blue-color;
      @include box-shadow(v.$primary-blue-color);
    }

    .p-dropdown-trigger-icon {
      font-size: 10px;
    }
  }

  &.ng-dirty.ng-invalid {
    > .p-dropdown {
      border-color: v.$error-color;
    }

    .p-dropdown:not(.p-disabled).p-focus {
      @include box-shadow(v.$error-color);
    }
  }
}

.p-dropdown-panel {
  max-width: 0;

  .p-dropdown-items {
    .p-dropdown-item {
      border-left: 3px #ffffff solid;
      overflow-wrap: anywhere;
      white-space: pre-wrap;

      &.p-highlight,
      &:not(.p-highlight):not(.p-disabled):hover {
        background: #ffffff;
        border-left: 3px v.$primary-blue-color solid;
        color: v.$primary-blue-color;
      }
    }
  }
}

// File Upload
.p-fileupload {
  .p-fileupload-buttonbar {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .p-fileupload-choose {
      border-radius: 30px;
      height: 36px;

      &:not(.p-disabled):hover {
        background: rgba(v.$primary-blue-color, 0.92);
      }

      &:active {
        background: rgba(v.$primary-blue-color, 0.68);
      }

      .p-button-icon-left {
        font-size: 14px;
        height: 14px;
        width: 14px;
      }
    }
  }

  .p-fileupload-content {
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-top: none;
    display: none;
    padding: 1rem;
    border-top: 0px;

    .p-progressbar {
      display: none;
    }

    .p-messages {
      margin-bottom: 15px;

      .p-message.p-message-error {
        margin: 0;

        .p-message-wrapper {
          display: grid;
          grid-template-columns: 32px 1fr;
          position: relative;

          .p-message-detail {
            grid-column-start: 2;
            margin-left: 0;
          }

          .p-message-close {
            $button-size: 20px;
            color: #ffffff;
            background: #d32f2f;
            border: 0 none;
            height: $button-size;
            position: absolute;
            right: calc(0px - (#{$button-size} / 2));
            top: calc(0px - (#{$button-size} / 2));
            width: $button-size;

            ::ng-deep span.p-button-icon.pi.pi-times {
              font-size: 0.75rem;
            }
          }
        }
      }
    }

    .p-fileupload-files {
      display: none;
    }
  }
}

p-fileupload {
  &.required .p-fileupload-buttonbar .p-fileupload-choose .p-button-label::after {
    color: v.$error-color;
    content: ' *';
  }

  &.has-content {
    .p-fileupload-buttonbar {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .p-fileupload-content {
      border-top: 1px solid rgba(0, 0, 0, 0.38);
      display: block;
    }
  }
}

// Float Label
.p-float-label {
  font-size: $form-el-font-size;

  &.required label::after {
    color: v.$error-color;
    content: ' *';
  }

  input:focus ~ label,
  .p-inputwrapper-focus ~ label {
    color: v.$primary-blue-color;
  }

  > .ng-invalid.ng-dirty + label {
    color: v.$error-color;
  }
}

// Input
.p-inputtext {
  font-family: 'Work Sans', sans-serif;
  font-size: $form-el-font-size;
  height: $form-el-height;
  line-height: $form-el-font-size;
  width: 100%;

  &:enabled:focus {
    border-color: v.$primary-blue-color;
    @include box-shadow(v.$primary-blue-color);
  }

  &.ng-dirty.ng-invalid {
    border-color: v.$error-color;
  }

  &:enabled:focus.ng-invalid.ng-dirty {
    @include box-shadow(v.$error-color);
  }
}

.p-inputgroup {
  height: $form-el-height;

  i.pi.pi-times {
    cursor: pointer;
    z-index: 999;
  }

  .p-inputgroup-addon {
    &.datepicker {
      border: 1px solid rgba(0, 0, 0, 0.38);
      border-left: 0;
      padding: 0px;
    }
  }
}

// Input Number
p-inputnumber {
  width: 100%;

  .p-inputnumber {
    width: 100%;
  }

  &.ng-dirty.ng-invalid {
    > .p-inputnumber > .p-inputtext {
      border-color: v.$error-color;
    }

    .p-inputtext:enabled:focus {
      @include box-shadow(v.$error-color);
    }
  }
}

// Multiselect
p-multiSelect {
  .p-multiselect {
    height: $form-el-height;
    line-height: $form-el-font-size;
    width: 100%;

    &:not(.p-disabled).p-focus {
      border-color: v.$primary-blue-color;
      @include box-shadow(v.$primary-blue-color);
    }

    &.p-multiselect-chip .p-multiselect-token {
      background: rgba(v.$primary-blue-color, 0.12);
      color: v.$primary-blue-color;
    }

    .p-multiselect-trigger-icon {
      font-size: 10px;
    }

    .p-multiselect-items .p-multiselect-item {
      .p-ink {
        background-color: rgba(v.$primary-blue-color, 0.16);
      }

      &:focus.p-highlight {
        background: none; // Needed
      }
    }
  }

  .p-multiselect-label {
    display: flex;
    flex-wrap: wrap;
  }

  &.ng-dirty.ng-invalid > .p-multiselect {
    border-color: v.$error-color;
  }

  &.not-empty {
    .p-multiselect.p-multiselect-chip {
      height: auto;
    }
  }
}

.p-multiselect-panel {
  max-width: 0;

  .p-multiselect-items {
    .p-multiselect-item {
      border-left: 3px #ffffff solid;
      overflow-wrap: anywhere;
      white-space: pre-wrap;

      &.p-highlight,
      &:not(.p-highlight):not(.p-disabled):hover {
        background: #ffffff;
        border-left: 3px v.$primary-blue-color solid;
        color: v.$primary-blue-color;
      }
    }
  }
}

// Overlay Panel
.p-overlaypanel {
  .p-overlaypanel-close {
    background-color: v.$light-blue-color;
    color: v.$primary-blue-color;
    right: 1rem;
    top: 1rem;

    &:enabled:hover {
      background-color: v.$light-blue-color;
      color: v.$primary-blue-color;
    }
  }

  &.profile-picture-overlay {
    border-radius: 10px;
  }
}

// Password
p-password {
  &.ng-invalid.ng-dirty > .p-password > .p-inputtext {
    border-color: v.$error-color;

    &:enabled:focus {
      @include box-shadow(v.$error-color);
    }
  }

  .p-password {
    width: 100%;
  }
}

.p-password-panel {
  &.p-password-meter {
    background: rgba(v.$primary-blue-color, 0.32);
  }

  ul {
    list-style-position: inside;
    padding-inline-start: 14px;

    > li:first-of-type {
      list-style-type: none;
    }
  }
}

// Picklist
.p-picklist .p-picklist-list .p-picklist-item {
  &.p-highlight {
    color: v.$primary-blue-color;
    background: rgba(v.$primary-blue-color, 0.12);
  }

  &:focus.p-highlight {
    background: rgba(v.$primary-blue-color, 0.24);
  }
}

// Progress Bar
.p-progressbar {
  $bar-height: 6px;
  background: rgba(v.$grey-color, 0.32);
  border-radius: $bar-height;
  height: $bar-height;

  .p-progressbar-value {
    background: v.$primary-blue-color;
  }

  .p-progressbar-label {
    line-height: $bar-height;
  }
}

// Radio button
p-radiobutton {
  .ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    border-color: v.$error-color;
  }
}

.p-radiobutton {
  .p-radiobutton-box {
    &:not(.p-disabled).p-focus {
      border-color: v.$primary-blue-color;
    }

    &.p-highlight {
      border-color: v.$primary-blue-color;

      &:not(.p-disabled) {
        &:hover {
          border-color: v.$primary-blue-color;
          color: v.$primary-blue-color;
        }

        &.p-focus {
          border-color: v.$primary-blue-color;
        }
      }
    }

    .p-radiobutton-icon {
      background-color: v.$primary-blue-color;
    }
  }

  &.p-radiobutton-checked:not(.p-radiobutton-disabled) {
    &:hover {
      @include box-shadow(v.$primary-blue-color);
    }

    &.p-radiobutton-focused {
      @include box-shadow(v.$primary-blue-color);
    }
  }
}

// Rating
.p-rating {
  .p-rating-icon {
    color: v.$primary-blue-color;

    &.p-rating-cancel {
      color: v.$error-color;

      &:focus {
        background: rgba(v.$error-color, 0.12);
      }
    }

    &.pi-star-fill {
      color: v.$primary-blue-color;
    }

    &:focus {
      background: rgba(v.$primary-blue-color, 0.12);
    }
  }

  &:not(.p-disabled):not(.p-readonly) .p-rating-icon {
    &:hover {
      color: v.$primary-blue-color;
    }

    &.p-rating-cancel:hover {
      color: v.$error-color;
      background: rgba(v.$error-color, 0.04);
    }
  }
}

// Select Button
.p-selectbutton .p-button {
  &.p-highlight {
    background: v.$primary-blue-color;
    border-color: v.$primary-blue-color;
    color: rgba(255, 255, 255, 0.87);

    .p-button-icon-left,
    .p-button-icon-right {
      color: rgba(255, 255, 255, 0.6);
    }

    &:hover {
      background: v.$primary-blue-color;
      border-color: v.$primary-blue-color;
      color: rgba(255, 255, 255, 0.87);

      .p-button-icon-left,
      .p-button-icon-right {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  &:focus {
    background: v.$primary-blue-color;
    border-color: v.$primary-blue-color;

    &.p-highlight {
      background: v.$primary-blue-color;
      border-color: v.$primary-blue-color;
    }
  }
}

p-selectbutton {
  .ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: v.$error-color;
  }
}

.stepper-filedList {
  .full {
    .checkbox-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      p-checkbox {
        flex: 0 0 25%;
        max-width: 25%;
        padding-right: 15px;
        margin-bottom: 20px;
      }
    }
  }
}

.reviewPending-publishedSubTabsWrap {
  margin-bottom: 20px;
  .mat-tab-header {
    border: 0px !important;
  }
  .mat-tab-list {
    .mat-tab-labels {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .mat-tab-label {
        font-size: 16px;
        font-weight: 500;
        width: calc(50% - 50px);
        padding: 30px 20px;
        background: #fff;
        color: rgb(0, 0, 0, 0.5);
        opacity: 1;
        border-radius: 15px;
        border: 1px solid #e0e0e0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        @media screen and (min-width: 1735px) {
          font-size: 26px;
          padding: 50px 20px;
        }
        @media (max-width: 479px) {
          width: 100%;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0px;
          }
        }
        &.mat-tab-label-active {
          color: #000;
        }
      }
    }
    .mat-ink-bar {
      display: none !important;
    }
  }
  .mat-tab-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .mat-tab-link {
      flex-basis: unset;
      flex-grow: unset;
      font-size: 16px !important;
      font-weight: 500 !important;
      width: calc(50% - 50px);
      padding: 30px 20px !important;
      background: #fff;
      color: rgba(0, 0, 0, 0.5) !important;
      opacity: 1;
      border-radius: 15px;
      border: 1px solid #e0e0e0;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
      @media screen and (min-width: 1735px) {
        font-size: 26px !important;
        padding: 50px 20px !important;
      }
      @media (max-width: 479px) {
        width: 100%;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
      &.mat-tab-label-active {
        color: #000;
      }
    }
  }
}

.reviewSubjectTable-wrappers {
  .mat-expansion-panel {
    box-shadow: none;
    mat-expansion-panel-header {
      .accordion-subjectName {
        margin-top: 40px;
      }
    }
    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0px !important;
        table {
          tbody {
            tr {
              td {
                padding: 10px;
                @media screen and (min-width: 1735px) {
                  font-size: 20px;
                  line-height: 23.5px;
                  padding: 20px 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.draftReview-tableWrapper {
  margin-top: 15px;
  border-spacing: 0px 10px;
  table {
    thead {
      tr {
        .drafReviewTable-subjectName {
          font-size: 18px;
          font-weight: 400 !important;
          line-height: 28px;
          text-align: left;
          color: #000000;
          border: 1px solid #e0e0e0;
          border-radius: 15px;
          margin: 0px 0px 10px;
          padding: 15px 35px;
          height: auto;
          box-shadow: none;
          @media screen and (min-width: 1735px) {
            font-size: 24px;
            line-height: 28px;
          }
        }
      }
    }
    tbody {
      tr {
        td.drafReviewTable-cell {
          color: #000;
          white-space: pre-wrap;
          word-wrap: break-word;
          background: rgb(217, 217, 217, 0.29);
          border-radius: 15px;
          padding: 10px 35px;
          margin-top: 15px;
          font-size: 14px;
          line-height: 24px;
          @media screen and (min-width: 1735px) {
            font-size: 20px;
            line-height: 28px;
          }
          .drafReviewTable-cellInner {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .drafReviewTable-elementName {
              flex: 0 0 calc(100% - 100px);
              max-width: calc(100% - 100px);
            }
            .drafReviewTable-editBtn {
              flex: 0 0 100px;
              max-width: 100px;
              text-align: center;
              button {
                border: 1px solid #f09428;
                background: #f09428;
                color: #fff;
                font-size: 16px;
                padding: 7px 25px;
                border-radius: 5px;
                cursor: pointer;
                margin: auto;
                width: auto;
                height: auto;
                line-height: normal;
                .mat-icon {
                  font-size: 20px;
                  margin-right: 5px;
                }
                span {
                  margin-right: 0px !important;
                }
              }
            }
          }
          .drafReviewTable-cellInner.historyBtnWrapper {
            .drafReviewTable-elementName {
              flex: 0 0 calc(100% - 220px);
              max-width: calc(100% - 220px);
            }
            .drafReviewTable-editBtn {
              flex: 0 0 220px;
              max-width: 220px;
              text-align: center;
              button {
                padding: 7px 15px;
                &:first-child {
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.feedbackDialog {
  width: 100%;
  max-width: 550px !important;
  .addFeedbackpopupWrapper {
    .addFeedbackpopup-heder {
      position: relative;
      padding: 20px 30px;
      border-bottom: 1px solid #e0e0e0;
      .addFeedbackpopup-HD {
        font-size: 24px;
        color: #f09428;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .addFeedbackpopup-content {
      padding: 25px 35px;
      ul.feedbackUnfo-listWrapper {
        padding-left: 0px;
        list-style-type: none;
        margin: 0px;
        max-height: 250px;
        overflow-y: auto;
        li.feedbackInfo-list {
          position: relative;
          padding-left: 40px;
          color: #000;
          font-size: 14px;
          line-height: 18px;
          &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background: #f09428;
          }
          @media screen and (min-width: 1735px) {
            font-size: 18px;
            line-height: 21px;
            &:before {
              width: 30px;
              height: 30px;
              border-radius: 30px;
            }
          }
          .feedbackApproval-name {
            color: #000;
            font-size: 16px;
            line-height: 20px;
            @media screen and (min-width: 1735px) {
              font-size: 20px;
              line-height: 23px;
            }
          }
          .feedbackPost-message {
            margin-top: 20px;
          }
          .feedbackSender-email {
            color: #5283d8;
          }
        }
      }
      .feedbackPost-form {
        position: relative;
        padding-left: 40px;
        margin-top: 25px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background: #f09428;
        }
        @media screen and (min-width: 1735px) {
          &:before {
            width: 30px;
            height: 30px;
            border-radius: 30px;
          }
        }
        .feedbackTextAreaBox {
          .feedbackTextArea {
            width: 100%;
            height: 70px;
            background: #f7f7f7;
            border: 1px solid #e0e0e0 !important;
            resize: none;
            padding: 10px;
            border-radius: 9px;
          }
        }
        .feedback-buttonBox {
          text-align: center;
          .reviewPopUp-cancelBtn {
            border: 1px solid #f09428;
            background: #f09428;
            color: #fff;
            font-size: 14px;
            padding: 12px 15px;
            border-radius: 5px;
            cursor: pointer;
            width: 100%;
            max-width: 150px;
            text-align: center;
            margin: 15px auto 0px;
            @media screen and (min-width: 1735px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.container-wrapper {
  width: 100%;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0px auto;
}

.emptyValid {
  min-height: 20px;
}
.listStyleNone {
  list-style-type: none;
  padding-left: 0px;
}

.mat-progress-spinner {
  width: 25px !important;
  height: 25px !important;
  margin: auto;
  svg {
    width: 25px !important;
    height: 25px !important;
  }
}

.reviewTable-wrapper.contentReviewTableWrap {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px !important;
    thead {
      tr {
        th {
          font-family: Rubik;
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: 0em;
          text-align: left;
          padding: 10px 20px;
          min-width: 150px !important;
          color: rgba(0, 0, 0, 0.87);
          @media screen and (min-width: 1735px) {
            padding: 20px 40px;
            font-size: 24px;
          }
        }
        th.actionCell {
          padding: 0px 0px;
          min-width: 150px !important;
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        td {
          font-family: Rubik;
          font-size: 14px;
          font-weight: 400;
          line-height: 23.8px;
          text-align: left;
          padding: 20px 20px;
          min-width: 150px !important;
          background: rgb(217, 217, 217, 0.27);
          color: rgba(0, 0, 0, 0.87);
          &:first-child {
            border-radius: 15px 0px 0px 15px;
          }
          &:last-child {
            border-radius: 0px 15px 15px 0px;
          }
          @media screen and (min-width: 1735px) {
            font-size: 20px;
            padding: 35px 40px;
          }
        }
        td.actionCell {
          padding: 0px 0px;
          min-width: 130px !important;
          text-align: center;
          button.review-btn {
            border: 1px solid #f09428;
            background: #f09428;
            color: #fff;
            font-size: 16px;
            padding: 7px 15px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: auto;
            span {
              display: inline-block;
              margin-right: 5px;
              img {
                width: 26px;
              }
            }
          }
        }
      }
    }
  }
}
.viewbtn {
  border: 1px solid #217a46 !important;
  background: #fff;
  color: #217a46;
  font-size: 16px;
  padding: 7px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: auto;
}

.pad0 {
  padding: 0px !important;
}
.p10 {
  padding: 10px !important;
}

.review-search {
  margin-left: 1.52em;
  margin-top: 1.5em;
}

span.mat-expansion-indicator {
    position: absolute !important;
    left: 11em;
}
.popupDragandDropTable{
  width:100%;
}
.popupDragandDropTable td{
  border-top:1px solid #ccc;
}

.popupDragandDropTable td mat-icon.mat-icon.notranslate.material-icons.mat-icon-no-color {
    position: relative;
    right: 6px;
}